<template>
  <div class="product-list">
    <div class="product-selection">
      <div v-for="item in firstTypes" :key="`firstTypes-${item.id}`" :class="{ active: item.id === firstTypeId }" @click="queryPproductsList(item.id)">
        <img :src="item.imgPath" :alt="item.typeName" />
        <div>{{ item.typeName }}</div>
      </div>
      <!-- <div class="active" @click="selectType(1)">
        <img src="@/assets/image/product-list-icon-01.png" alt="" />
        <div>气象产品</div>
      </div>
      <div @click="selectType(2)">
        <img src="@/assets/image/product-list-icon-02.png" alt="" />
        <div>环境产品</div>
      </div> -->
      <!-- <div>
        <img src="@/assets/image/product-list-icon-03.png" alt="" />
        <div>交通产品</div>
      </div>
      <div>
        <img src="@/assets/image/product-list-icon-04.png" alt="" />
        <div>农业产品</div>
      </div>
      <div>
        <img src="@/assets/image/product-list-icon-05.png" alt="" />
        <div>能源相关</div>
      </div> -->
    </div>
    <div class="type-selection">
      <div>
        类型：
      </div>
      <div v-for="item in secondTypes" :key="`secondTypes-${item.id}`" :class="{ active: item.id === secondTypeId }" @click="queryPproductsList(firstTypeId, item)">
        {{ item.typeName }}
      </div>
    </div>
    <div class="product-list-box">
      <div>
        <div v-for="item in productsList" :key="`product-${item.id}`" class="product-item" @click="jumpPage('ProductDetails', item.id)">
          <div>
            <img :src="item.imgPath" :alt="item.imgTitle" />
          </div>
          <div>
            <div class="product-name">{{ item.imgTitle }}</div>
            <div class="product-introduction ellipsis-2">{{ item.imgIntroduce }}。</div>
            <div class="learn-more">→ 了解更多</div>
          </div>
        </div>
        <!-- <div class="product-item">
          <div>
            <img src="@/assets/image/temporary/humidity-sensor.jpg" alt="" />
          </div>
          <div>
            <div class="product-name">湿度传感器</div>
            <div class="product-introduction">融合应用、SAN/NAS、异构、免网关—体化双活等特性的全新—代中端入门级智能混合闪存系统。</div>
            <div class="learn-more">→ 了解更多</div>
          </div>
        </div>
        <div class="product-item">
          <div>
            <img src="@/assets/image/temporary/530-series-sensors.jpg" alt="" />
          </div>
          <div>
            <div class="product-name">530系列传感器</div>
            <div class="product-introduction">融合应用、SAN/NAS、异构、免网关—体化双活等特性的全新—代中端入门级智能混合闪存系统。</div>
            <div class="learn-more">→ 了解更多</div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="./index.ts"></script>

<style lang="scss" scoped>
.product-list {
  width: $effectiveArea;
  margin: 0 auto;
  .product-selection {
    padding-top: 36px;
    display: flex;
    justify-content: center;
    text-align: center;
    > div {
      cursor: pointer;
      & + div {
        margin-left: 62px;
      }
      &:hover,
      &.active {
        color: $focusFontColor;
      }
    }
    img {
      margin-bottom: 8px;
      height: 44px;
    }
  }
  .type-selection {
    display: flex;
    padding-top: 30px;
    padding-bottom: 20px;
    div {
      &:first-child {
        margin-right: 22px;
      }
      &:not(:first-child) {
        cursor: pointer;
        transition-duration: 0.5s;
        & + div {
          margin-left: 38px;
        }
        &:hover,
        &.active {
          color: $focusFontColor;
        }
      }
    }
  }
  &-box {
    padding-bottom: 20px;
    > div {
      &:first-child {
        display: grid;
        grid-template-columns: repeat(3, 397px);
        grid-column-gap: 25px;
        grid-row-gap: 30px;
        border: 1px solid #e5e5e5;
        box-shadow: 0px 0px 28px 1px rgba(5, 48, 88, 0.09);
        border-radius: 4px;
        padding: 31px;
      }
      .product-item {
        transition-duration: 0.5s;
        overflow: hidden;
        cursor: pointer;
        &:hover {
          box-shadow: 0px 0px 28px 1px rgba(5, 48, 88, 0.16);
          border-radius: 4px;
          .product-name:before {
            left: 0;
            right: 0;
          }
          > div {
            &:first-child {
              img {
                width: 110%;
                height: 110%;
                left: -5%;
                top: -5%;
              }
            }
          }
        }
        > div {
          &:first-child {
            position: relative;
            width: 397px;
            height: 353px;
            img {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              margin: auto;
              width: 100%;
              height: 100%;
              object-fit: cover;
              transition-duration: 0.5s;
            }
          }
          &:last-child {
            padding: 26px 16px;
            font-size: 16px;
          }
        }
        .product-name {
          font-size: 20px;
          font-weight: bold;
          display: inline-block;
          vertical-align: middle;
          transform: perspective(1px) translateZ(0);
          box-shadow: 0 0 1px rgba(0, 0, 0, 0);
          position: relative;
          overflow: hidden;
          &:before {
            content: "";
            position: absolute;
            z-index: -1;
            left: 51%;
            right: 51%;
            bottom: 0;
            background: #e5e5e5;
            height: 2px;
            transition-property: left, right;
            transition-duration: 0.3s;
            transition-timing-function: ease-out;
          }
        }
        .product-introduction {
          margin: 28px 0;
          min-height: 45px;
        }
        .learn-more {
          color: #2da3e5;
        }
      }
    }
  }
}
</style>
