import { defineComponent, reactive, toRefs } from "vue";
import { useRouter, useRoute } from "vue-router";
import { getByUrl } from "@/utils/http";

interface Data {
  productsList: Array<{
    applicationScenarios: null;
    caseDetailsId: null;
    fieldParameter: null;
    id: number;
    imgDetails: null;
    imgIntroduce: string;
    imgName: null;
    imgPath: string;
    imgTitle: string;
    succId: null;
    typeId: null;
  }>;
  firstTypes: Array<{ id: number; imgPath: string; typeName: string }>;
  secondTypes: Array<{ id: number; firstId: number; typeName: string }>;
  firstTypeId: number | null | undefined;
  secondTypeId: number | null | undefined;
  jumpPage: (name: string, productsId: number) => void;
}

export default defineComponent({
  name: "NewsInformation",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const data: Data = reactive({
      firstTypeId: null,
      secondTypeId: null,
      productsList: [],
      firstTypes: [],
      secondTypes: [],
      jumpPage: (name, productsId) => {
        router.push({ name, params: { productsId } });
      }
    });
    const queryPproductsList = (firstTypeId?: number, secondTypeObj?: { firstId: number; id: number; typeName: string }) => {
      let params: {
        firstTypeId?: number;
        secondTypeId?: number;
      } = {};
      if (Object.keys(route.params).length && !firstTypeId && !secondTypeObj) {
        params = route.params;
      }
      if (firstTypeId) data.firstTypeId = params.firstTypeId = firstTypeId;
      if (secondTypeObj) {
        data.firstTypeId = params.firstTypeId = secondTypeObj?.firstId;
        data.secondTypeId = params.secondTypeId = secondTypeObj?.id;
      }

      getByUrl("/relatedProducts/getProducts", params).then(res => {
        data.productsList = res.productsList;
        if (!data.firstTypes.length) data.firstTypes = res.firstTypes;
        data.secondTypes = [{ firstId: res.firstTypeId, id: 0, typeName: "全部" }].concat(res.secondTypes);
        if (!firstTypeId) data.firstTypeId = res.firstTypeId;
        if (!secondTypeObj) data.secondTypeId = res.secondTypeId;
        router.push({ name: "ProductList", params: { firstTypeId: res.firstTypeId, secondTypeId: res.secondTypeId } });
      });
    };
    queryPproductsList();
    return {
      ...toRefs(data),
      queryPproductsList
    };
  }
});
